export default {
    mounted() {
      this.setupTonConnectListeners();
    },
    beforeUnmount() {
      this.removeTonConnectListeners();
    },
    methods: {
      setupTonConnectListeners() {
        window.addEventListener('ton-connect-ui-connection-started', this.handleConnectionStarted);
        window.addEventListener('ton-connect-ui-connection-completed', this.handleConnectionCompleted);
        window.addEventListener('ton-connect-ui-connection-error', this.handleConnectionError);
        window.addEventListener('ton-connect-ui-connection-restoring-started', this.handleConnectionRestoringStarted);
        window.addEventListener('ton-connect-ui-connection-restoring-completed', this.handleConnectionRestoringCompleted);
        window.addEventListener('ton-connect-ui-connection-restoring-error', this.handleConnectionRestoringError);
        window.addEventListener('ton-connect-ui-disconnection', this.handleDisconnection);
        window.addEventListener('ton-connect-ui-transaction-sent-for-signature', this.handleTransactionSentForSignature);
        window.addEventListener('ton-connect-ui-transaction-signed', this.handleTransactionSigned);
        window.addEventListener('ton-connect-ui-transaction-signing-failed', this.handleTransactionSigningFailed);
      },
      removeTonConnectListeners() {
        window.removeEventListener('ton-connect-ui-connection-started', this.handleConnectionStarted);
        window.removeEventListener('ton-connect-ui-connection-completed', this.handleConnectionCompleted);
        window.removeEventListener('ton-connect-ui-connection-error', this.handleConnectionError);
        window.removeEventListener('ton-connect-ui-connection-restoring-started', this.handleConnectionRestoringStarted);
        window.removeEventListener('ton-connect-ui-connection-restoring-completed', this.handleConnectionRestoringCompleted);
        window.removeEventListener('ton-connect-ui-connection-restoring-error', this.handleConnectionRestoringError);
        window.removeEventListener('ton-connect-ui-disconnection', this.handleDisconnection);
        window.removeEventListener('ton-connect-ui-transaction-sent-for-signature', this.handleTransactionSentForSignature);
        window.removeEventListener('ton-connect-ui-transaction-signed', this.handleTransactionSigned);
        window.removeEventListener('ton-connect-ui-transaction-signing-failed', this.handleTransactionSigningFailed);
      },
      handleConnectionStarted(event) {
        console.log('TONCONNECTUI: Connection started', event.detail);
        // Add your custom logic here
      },
      handleConnectionCompleted(event) {
        console.log('TONCONNECTUI: Connection completed', event.detail);
        // Add your custom logic here
      },
      handleConnectionError(event) {
        console.log('TONCONNECTUI: Connection error', event.detail);
        // Add your custom logic here
      },
      handleConnectionRestoringStarted(event) {
        console.log('TONCONNECTUI: Connection restoring started', event.detail);
        // Add your custom logic here
      },
      handleConnectionRestoringCompleted(event) {
        console.log('TONCONNECTUI: Connection restoring completed', event.detail);
        // Add your custom logic here
      },
      handleConnectionRestoringError(event) {
        console.log('TONCONNECTUI: Connection restoring error', event.detail);
        // Add your custom logic here
      },
      handleDisconnection(event) {
        console.log('TONCONNECTUI: Disconnection', event.detail);
        // Add your custom logic here
      },
      handleTransactionSentForSignature(event) {
        console.log('TONCONNECTUI: Transaction sent for signature', event.detail);
        // Add your custom logic here
      },
      handleTransactionSigned(event) {
        console.log('TONCONNECTUI: Transaction signed', event.detail);
        // Add your custom logic here
      },
      handleTransactionSigningFailed(event) {
        console.log('TONCONNECTUI: Transaction signing failed', event.detail);
        // Add your custom logic here
      }
    }
  };