import { createI18n } from 'vue-i18n'

const messages = {
  en: {

    wallet: "Wallet",
    wallets: {
        connect: "Connect Wallet",
        howto: "How to get TON wallet?",
    },

    learn: "Learn More",
    group: "Group",
    channel: "Channel",

    continue: "Continue",
    done: "Done",
    skip: "Skip",

    key: "Key",
    buy: "Buy",

    catalog:
    { 
        keys: "Keys Catalog",
    },

    join: {
        title: "Join Keyton",
        start_bot: "Start Bot",
        step1: "Your E-mail",
        step1intro: "Let's keep in touch",
        step1head: "Enter your e-mail adress:",
        step1text: "By providing your email address, you consent to receiving our bi-weekly project development newsletter. Please be aware that your email will not be used to log into your Keyton account.",
        step1info: "",
        step2: "Start Bot",
        step2intro: "Get notified when your Keys are purchased",
        step2head: "Start the bot in Telegram:",
        step2text: "",
        step2info: "",
        step3: "Connect Wallet",
        step3intro: "Used to sign transactions and access your account",
        step3head: "Connect your TON digital wallet:",
        step3text: "",
        step3info: "",
        emailRegistrationFailed: "Please check e-mail format.",
        botActivationFailed: "Error2",
        walletConnectionFailed: "Error3",
    },

    list: {
        resource: "Resource",
        apy: "APY",
        key: "Key",
        keys: "Keys",
        price: "Price",
    },

    mint: {
        key: "Mint Key",
        create: "Create Key",
        new: "Mint New Key",
    },

    home: {
        spot: {
            head: "Keys for Telegram Groups & Channels",
            text: "Monetize popular resources using $TON (Toncoin) on The Open Network",
            word1: "Open",
            word2: "Mint",
            word3: "Trade",
            word4: "Sell",
            word5: "Hold",
            press: "Quick Tour",
        },
        list: {
            title: "TOP APY KEYS",
        },
        works: {
            title: "How it works?",
            head1: "Create Key to Any Channel",
            text1: "Be the frist to mint a a Key for a popular channel or a group in Telegram. You don't need to be a owner or admin!",
            head2: "Buy a Key to Raise Its Price",
            text2: "Hunt promising Keys and watch their value grow. Every next Key will cost way more.",
            head3: "Instant Sell Keys",
            text3: "Sell your keys anytime without the need for a buyer, ensured by liquidity on the service protocol.",
            head4: "Hold for Daily Income",
            text4: "Collect daily share of the key fund distributinon between all the keys holders.",
        },
        grossing: {
            title: "Top Grossing",
        },
        start: {
            title: "Start Today",
            head1: "Channel Owner?",
            text1: "Claim ownership of your channel and claim accumulated proceeds in TON.",
            press1: "Owners",
            head2: "Create New Key",
            text2: "Be the first to create a key of the popular Telegram channel or a group.",
            press2: "New Key",
        },
        new: {
            title: "New Keys",
        },
        faq: {
            title: "FAQ",
            q: "Question",
            a: "Answer",
            q1: "How to trade Keys?",
            a1: "Well, in order to trade the key you need to have a wallet with TON coins. Well, in order to trade the key you need to have a wallet with TON coins.",
        },
        social: {
            title: "Follow us on social:",
        },
    },

    terms: "Terms",
    privacy: "Privacy Policy",
    feedback: "Feedback",

  },
  de: {
    balance: "Kontostand",
   
    wallets: {
        connect: "Connecto",
    },

  }
  
}

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

export default i18n