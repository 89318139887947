import { createStore } from 'vuex';
import { TonConnectUI, TonConnectUIError, WalletAlreadyConnectedError } from '@tonconnect/ui';
import axios from 'axios';
import api from './api'; // intercetpor

const tonConnectUI = new TonConnectUI({
  manifestUrl: process.env.VUE_APP_TON_MANIFEST,
});

tonConnectUI.onModalStateChange((state) => {

    if (state.status === 'error') {
        alert('TonConnect modal error:', state.error)
        console.error('TonConnect modal error:', state.error)
        showErrorNotification('An error occurred with the wallet connection. Please try again.')
    }

    console.log('modal state changed = '+JSON.stringify(state)); // !!

    if(state.status === 'closed') {
        store.commit('SET_WALLET_CONNECTING', false);
    }

    // state.closeReason ==='wallet-selected' - when connected succesfully

    if (state.status === 'closed' && state.closeReason === 'action-cancelled') {
        store.dispatch('showNotification', {
            message: 'Wallet connection cancelled',
            type: 'info'
        });
    } 
    // else if(state.status === 'closed') {
    //     store.dispatch('showNotification', {
    //         message: 'Wallet connection aborted', // pops up every time new wallet reconnected
    //         type: 'info'
    //     });
    // }
});

    // TON Connect Data
    // not reactive!
    // const currentWallet = tonConnectUI.wallet;
    // const currentWalletInfo = tonConnectUI.walletInfo;
    // const currentAccount = tonConnectUI.account;
    // const currentIsConnectedStatus = tonConnectUI.connected;
    // alert('currentWallet='+JSON.stringify(currentWallet)); // same as wallet.account
    // alert('currentWalletInfo='+JSON.stringify(currentWalletInfo)); // {"name":"Tonkeeper","appName":"tonkeeper","imageUrl":"https://tonkeeper.com/assets/tonconnect-icon.png","aboutUrl":"https://tonkeeper.com","tondns":"tonkeeper.ton","platforms":["ios","android","chrome","firefox","macos"],"bridgeUrl":"https://bridge.tonapi.io/bridge","universalLink":"https://app.tonkeeper.com/ton-connect","deepLink":"tonkeeper-tc://","jsBridgeKey":"tonkeeper","injected":false,"embedded":false,"isPreferred":true,"openMethod":"custom-deeplink"}
    // alert('currentAccount='+JSON.stringify(currentAccount)); // {"address":"0:49c16aa6668361631898319ae96fea603cd1ebcf9396aa5d6e9f701e523bb2a9","chain":"-239","walletStateInit":"te6cckECFgEAAwQAAgE0AgEAUQAAAAApqaMXYZWxWDosBaI31CoqJ2Sp/Kh8Q3CugjMbndUZEBvJZOpAART/APSkE/S88sgLAwIBIAkEBPjygwjXGCDTH9Mf0x8C+CO78mTtRNDTH9Mf0//0BNFRQ7ryoVFRuvKiBfkBVBBk+RDyo/gAJKTIyx9SQMsfUjDL/1IQ9ADJ7VT4DwHTByHAAJ9sUZMg10qW0wfUAvsA6DDgIcAB4wAhwALjAAHAA5Ew4w0DpMjLHxLLH8v/CAcGBQAK9ADJ7VQAbIEBCNcY+gDTPzBSJIEBCPRZ8qeCEGRzdHJwdIAYyMsFywJQBc8WUAP6AhPLassfEss/yXP7AABwgQEI1xj6ANM/yFQgR4EBCPRR8qeCEG5vdGVwdIAYyMsFywJQBs8WUAT6AhTLahLLH8s/yXP7AAIAbtIH+gDU1CL5AAXIygcVy//J0Hd0gBjIywXLAiLPFlAF+gIUy2sSzMzJc/sAyEAUgQEI9FHypwICAUgTCgIBIAwLAFm9JCtvaiaECAoGuQ+gIYRw1AgIR6STfSmRDOaQPp/5g3gSgBt4EBSJhxWfMYQCASAODQARuMl+1E0NcLH4AgFYEg8CASAREAAZrx32omhAEGuQ64WPwAAZrc52omhAIGuQ64X/wAA9sp37UTQgQFA1yH0BDACyMoHy//J0AGBAQj0Cm+hMYALm0AHQ0wMhcbCSXwTgItdJwSCSXwTgAtMfIYIQcGx1Z70ighBkc3RyvbCSXwXgA/pAMCD6RAHIygfL/8nQ7UTQgQFA1yH0BDBcgQEI9ApvoTGzkl8H4AXTP8glghBwbHVnupI4MOMNA4IQZHN0crqSXwbjDRUUAIpQBIEBCPRZMO1E0IEBQNcgyAHPFvQAye1UAXKwjiOCEGRzdHKDHrFwgBhQBcsFUAPPFiP6AhPLassfyz/JgED7AJJfA+IAeAH6APQEMPgnbyIwUAqhIb7y4FCCEHBsdWeDHrFwgBhQBMsFJs8WWPoCGfQAy2kXyx9SYMs/IMmAQPsABvNCG8k=","publicKey":"6195b1583a2c05a237d42a2a2764a9fca87c4370ae82331b9dd519101bc964ea"}
    // alert('currentIsConnectedStatus='+JSON.stringify(currentIsConnectedStatus)); // true

tonConnectUI.onStatusChange(async (wallet) => {

    console.log('tonConnectUI.onStatusChange - Received wallet data: '+JSON.stringify(wallet)); // !!
    if (wallet) {

        if(wallet.account.address) {
            store.commit('SET_WALLET', wallet.account.address);
            store.commit('SET_WALLET_CONNECTED', true);
        }

       let receivedProof = null;

        if (wallet.connectItems?.tonProof && 'proof' in wallet.connectItems.tonProof) {
            store.commit('SET_WALLET_DATA', {
                account: wallet.account, // contains address, publicKey, chain, walletStateInit
                connectItems: wallet.connectItems,
                data: JSON.stringify(wallet)
            });
            receivedProof = wallet.connectItems.tonProof.proof;

            console.log ('tonConnectUI onStatusChange - Will send proof to backend: ' + wallet.connectItems.tonProof.proof);

        } else {
            // wallet connected without proof
            store.commit('SET_WALLET_DATA', {
                account: wallet.account, // contains address, publicKey, chain, walletStateInit
                data: JSON.stringify(wallet),
                connectItems: null,
            });
            console.log ('tonConnectUI onStatusChange - No proof'); // so no jwt
            // but do not erase cause may have refresh active
        }

        const connectData = {
            data: {
                wallet_address: wallet.account.address,
                wallet_account: wallet.account,
                wallet_data: wallet,
                session: store.state.sessionToken,
                code_bot: store.state.codeBot,
                code_invite: store.state.codeInvite,
                proof: receivedProof // will be null if no proof
            },
            session: store.state.sessionToken
        };

        const response = await axios.post(process.env.VUE_APP_API_URL+'auth/connect', connectData);

        console.log('tonConnectUI onStatusChange - with: ' + JSON.stringify(connectData));
        console.log('tonConnectUI onStatusChange - auth/connect results: ' + JSON.stringify(response.data));

        if (response.data.status === 'success') {
            store.commit('SET_USER', response.data.user_data);
            if(response.data.user_data.bot_started===false) {
                store.commit('SET_START_BOT_SHOW', true);
                if(response.data.user_data.bot_code) store.commit('SET_START_BOT_CODE', response.data.user_data.bot_code);
            }
            if(response.data.user_auth) {
                console.log('tonConnectUI onStatusChange - JWT set');
                store.commit('SET_JWT', response.data.jwt);
                // alert('SET USER DATA = '+JSON.stringify(response.data.user_data));
                store.commit('SET_REFRESH_TOKEN', response.data.refresh_token );
            } else {
                console.log('tonConnectUI onStatusChange - No JWT received');
                // do nothing, its okay
            }
            if(response.data.user_new) {
                console.log('tonConnectUI onStatusChange - New User, launch tutorial');
            }
            if (response.data.bot_code) {
                store.commit('SET_START_BOT_CODE', response.data.bot_code);
            }
        } 
        store.dispatch('showNotification', { 
            message: 'Wallet connected successfully', 
            type: 'success' 
        });

        // tonConnectUI.closeModal(); // ??
        store.commit('SET_WALLET_CONNECTING', false);

        // if (receivedProof) {
        //     alert('proof received, call verifyTonProof in data');
        //     // Authenticate with the backend
        //     await store.dispatch('verifyTonProof', { 
        //         account: wallet.account,
        //         proof: wallet.connectItems.tonProof.proof
        //     });
        // }



    } else {
        store.commit('SET_WALLET', null);
        store.commit('SET_WALLET_DATA', null);
        store.commit('SET_WALLET_CONNECTED', false);
        store.commit('SET_WALLET_CONNECTING', false);
    }
  });

// const api = axios.create({
//   baseURL: process.env.VUE_APP_API_URL
// });

const store = createStore({
  state: {
    // general app
    appData: null,
    appLoad: false,
    appError: false,
    appErrorCode: null,
    appErrorText: null,
    // user and wallet
    user: null,
    walletAddr: null, // raw wallet from TON Connect
    walletData: null,
    walletConnected: false,
    walletConnecting: false,
    tonProof: null,
    startBotCode: null,
    startBotShow: false,
    // sess and auth
    sessionToken: localStorage.getItem('sessionToken'),
    jwt: localStorage.getItem('jwt'),
    refreshToken: localStorage.getItem('refreshToken'),
    // url params
    utmSource: '',
    utmData: '',
    codeBot: localStorage.getItem('codeBot'),
    codeInvite: localStorage.getItem('codeInvite'),
    // TX modal
    txStatus: '',
    txError: '',
    txDone: false,
    showTxSuccessModal: false,
    showTxErrorModal: false,
    // notification
    notification: null,
    // etc

  },
  mutations: {

    SET_TON_CONNECT_UI(state, instance) {
        state.tonConnectUI = instance;
    },
    SET_TON_PROOF(state, proof) {
        state.tonProof = proof;
    },

    // general app
    SET_APP_DATA(state, data) {
        // alert('SET_APP_DATA');
        state.appData = data;
    },
    SET_APP_LOAD(state, load) {
        state.appLoad = load;
    },
    SET_APP_ERROR(state, { code, text }) {
        alert("SET APP ERROR = "+JSON.stringify(code+' '+text)); // !!
        state.appError = true;
        state.appErrorCode = code;
        state.appErrorText = text;
    },

    // user and wallet
    SET_USER(state, user) {
        // alert("SET USER = "+JSON.stringify(user)); // !!
        console.log('SET user: '+JSON.stringify(user)); // !!
        state.user = user;
    },
    SET_START_BOT_CODE(state, code) {
        // alert("SET SET_START_BOT_CODE = "+code); // !!
        console.log('SET startBotCode: '+code); // !!
        localStorage.setItem('startBotCode', code);
        state.startBotCode = code;
    },
    SET_START_BOT_SHOW(state, show) {
        state.startBotShow = show;
    },
    SET_WALLET(state, wallet) {
        console.log('SET walletAddr: '+wallet);
        state.walletAddr = wallet;
    },
    SET_WALLET_DATA(state, walletData) {
        state.walletData = walletData;
    },
    SET_WALLET_CONNECTED(state, walletConnected) {
        console.log('SET walletConnected: '+walletConnected);
        state.walletConnected = walletConnected;
    },
    SET_WALLET_CONNECTING(state, walletConnecting) {
        console.log('SET walletConnecting: '+walletConnecting);
        state.walletConnecting = walletConnecting;
    },

    // methods
    SET_NOTIFICATION(state, notification) {
        state.notification = notification;
    },

    // get params
    SET_UTM_SOURCE(state, source) {
        state.utmSource = source;
    },
    SET_UTM_DATA(state, data) {
        state.utmData = data;
    },
    SET_CODE_BOT(state, code) {
        state.codeBot = code;
        localStorage.setItem('codeBot', code);
    },
    SET_CODE_INVITE(state, code) {
        state.codeInvite = code;
        localStorage.setItem('codeInvite', code);
    },

    // sess and auth
    SET_JWT(state, token) {
        state.jwt = token;
        if (token) {
          localStorage.setItem('jwt', token);
        } else {
          localStorage.removeItem('jwt');
        }
    },
    SET_REFRESH_TOKEN(state, token) {
        state.refreshToken = token;
        if (token) {
          localStorage.setItem('refreshToken', token);
        } else {
          localStorage.removeItem('refreshToken');
        }
    },
    SET_SESSION_TOKEN(state, token) {
        state.sessionToken = token;
        console.log('SET sessionToken: '+token);
        localStorage.setItem('sessionToken', token);
    },
    CLEAR_AUTH(state) {
        state.user = null;
        state.jwt = null;
        state.refreshToken = null;
        state.walletConnected = false;
        localStorage.removeItem('jwt');
        localStorage.removeItem('refreshToken');
    },
    // TX on all pages
    SET_TX_STATUS(state, status) {
        state.txStatus = status;
    },
    SET_TX_ERROR(state, error) {
        state.txError = error;
    },
    SET_TX_DONE(state, isDone) {
        state.txDone = isDone;
    },
    SHOW_TX_SUCCESS_MODAL(state, show) {
        state.showTxSuccessModal = show;
    },
    SHOW_TX_ERROR_MODAL(state, show) {
        state.showTxErrorModal = show;
    },

  },
  actions: {

    setGetParams({ commit }, { utmSource = '', utmData = '', botCode = null, inviteCode = null }) {
        console.log('setGetParams: '+utmSource+' '+utmData+' '+botCode+' '+inviteCode); // !!
        commit('SET_UTM_SOURCE', utmSource);
        commit('SET_UTM_DATA', utmData);
        if(botCode) commit('SET_CODE_BOT', botCode);
        if(inviteCode) commit('SET_CODE_INVITE', inviteCode);
    },

    showNotification({ commit }, { message, type }) {
      commit('SET_NOTIFICATION', { message, type });
      setTimeout(() => {
        commit('SET_NOTIFICATION', null);
      }, 5000);
    },

    hideNotification({ commit }) {
        setTimeout(() => {
        commit('SET_NOTIFICATION', null);
        }, 1);
    },

    setCodeBot({ commit }, code) {
        commit('SET_CODE_BOT', code);
    },
    setCodeInvite({ commit }, code) {
        commit('SET_CODE_INVITE', code);
    },

    async connectWallet({ commit, dispatch, state }) {
        alert('please use sign wallet');
    //     // alert('connectWallet');
    //     try {

    //         // await tonConnectUI.openModal();
    //         await tonConnectUI.connectWallet();

    //         // tonConnectUI.onStatusChange(async (wallet) => {
    //             // ... ?
    //         // }
        
    //         // const proof = await dispatch('generateTonProof');
    //         //   const connectedWallet = await tonConnectUI.connectWallet();
    //         //   return connectedWallet;

    //     } catch (error) {
    //         if (error instanceof TonConnectUIError) {
    //             if (error.message.includes("Wallet was not connected")) {
    //                 console.log("User cancelled wallet connection");
    //                 throw new Error('User cancelled wallet connection');
    //             }
    //             } else if (error instanceof WalletAlreadyConnectedError) {
    //                 console.log("Wallet is already connected");
    //                 throw new Error('Wallet is already connected');
    //                 // return tonConnectUI.wallet;
    //             }
    //       console.error('Failed to connect wallet:', error);
    //       throw error;
    //     }
    },

    async doConnect({ dispatch }) {
        try {
            dispatch('showNotification', { message: 'Connecting wallet...', type: 'info' });
            await dispatch('signWallet');
        } catch (error) {
            console.error('Wallet connection error:', error);
            dispatch('showNotification', { message: 'Failed to connect wallet: ' + error.message, type: 'error' });
        }
    },

    async doDisconnect({ dispatch}) {
        try {
            dispatch('showNotification', { message: 'Disconnecting wallet...', type: 'info' });
            await dispatch('disconnectWallet');
        } catch (error) {
            console.error('Wallet connection error:', error);
            dispatch('showNotification', { message: 'Failed to disconnect wallet: ' + error.message, type: 'error' });
        }
    },

    async signWallet({ dispatch, commit }) {
        try {

            commit('SET_WALLET_CONNECTING', true);

            // Set state to 'loading' while you are waiting for the response from your backend. If user opens connect wallet modal at this moment, he will see a loader.
            tonConnectUI.setConnectRequestParameters({
                state: 'loading'
            });

            const proof = await dispatch('generateTonProof');

            // alert('sending payload: '+proof.payload);

            // Set state to 'ready' and define tonProof value. Passed parameter will be applied to the connect request (QR and universal link).
            tonConnectUI.setConnectRequestParameters({
                state: 'ready',
                value: { tonProof: proof.payload }
            });

          // Create a Promise that resolves after setting connect parameters
            // await new Promise((resolve) => {
            //     tonConnectUI.setConnectRequestParameters({
            //         state: "ready",
            //         value: {
            //             tonProof: proof.payload
            //         }
            //     });
            //     // Use setTimeout to ensure setConnectRequestParameters has time to complete
            //     setTimeout(resolve, 0);
            // });

            // Now that setConnectRequestParameters has been called, connect the wallet
        
            await tonConnectUI.openModal();

            // const connectedWallet = await tonConnectUI.connectWallet({
                    // items: [
                    //   { name: 'ton_addr' },
                    //   { name: 'ton_proof', payload: proof.payload }
                    // ]
                //   });
        //   const connectedWallet = await tonConnectUI.connectWallet({
        //     items: [
        //       { name: 'ton_addr' },
        //       { name: 'ton_proof', payload: proof.payload }
        //     ]
        //   });
         // return connectedWallet;
        } catch (error) {
            console.log('TON WALLET ERROR CATCH '+error);
            commit('SET_WALLET_CONNECTING', false);
            if (error instanceof TonConnectUIError) {
                if (error.message.includes("Wallet was not connected")) {
                  console.log("User cancelled wallet connection");
                  throw new Error('User cancelled wallet connection');
                }
              } else if (error instanceof WalletAlreadyConnectedError) {
                console.log("Wallet is already connected");
                throw new Error('Wallet is already connected');
              }
          console.error('Failed to connect wallet:', error);
          throw error;
        }
    },

    // handleUnauthorized({ commit }) {
    //     commit('CLEAR_AUTH');
    //     router.push({ name: 'login' }); // Redirect to login page
    //   },

    async reconnectWallet({ dispatch, commit }) {
        
        // alert('reconnectWallet');
        await dispatch('disconnectWallet', { commit });
        await dispatch('signWallet', { dispatch, commit });
            
    },
  
    // async verifyTonProof({ commit }, { proof, account }) {
    //     alert('verifyTonProof: ' + JSON.stringify(proof) + ', account' + JSON.stringify(account));
    //     try {
    //       const response = await axios.post(process.env.VUE_APP_API_URL+'auth/verify_proof', {
    //         data: { proof, account }
    //       });
    //       commit('SET_JWT', response.data.jwt);
    //       // commit('SET_WALLET_CONNECTED', true);
    //       alert('verifyTonProof ' + JSON.stringify(response.data));
    //       return response.data;
    //     } catch (error) {
    //       console.error('Failed to verify TON proof:', error);
    //       throw error;
    //     }
    //   },
  
    async initSession({ dispatch, commit, state }) {

        try {

            console.log('Init app'); // !!
            const response = await axios.post(process.env.VUE_APP_API_URL+'sess/init', {
                language: navigator.language || navigator.userLanguage,
                user_agent: navigator.userAgent,
            });
            if (response.data.status === 'success') {
                // alert('!'+JSON.stringify(response.data));
                commit('SET_APP_DATA',response.data.public_data);
            } else {
              throw new Error(response.data.message || 'Failed to load session');
            }

            // console.log('get public user data');
            // await axios.post(process.env.VUE_APP_API_URL+'sess/init',{ data: {
            //     language: navigator.language || navigator.userLanguage,
            //     user_agent: navigator.userAgent,
            // }}).then((response) => {
                
                
            // }).catch(error => {
                
            // });

            const sessionData = {
                language: navigator.language || navigator.userLanguage,
                user_agent: navigator.userAgent,
                utm_source: state.utmSource,
                utm_data: state.utmData
            };
            
            console.log('initSession wif data: '+JSON.stringify(sessionData));
            if (state.sessionToken) {
                console.log('2 - There is token - do load session: '+state.sessionToken+' data: '+JSON.stringify(sessionData));
                dispatch('loadSession', { sessionToken: state.sessionToken, sessionData })
                .then(() => {
                    if(state.jwt) {
                        console.log('! - There is jwt - get userdata');
                        dispatch('getUserData', state.jwt);
                    } else if(state.walletAddr) {
                        dispatch('getUserInfo', state.walletAddr);
                    }
                })
                .catch(error => {
                    console.warn('Failed to load existing session:', error);
                    localStorage.removeItem('sessionToken');
                    console.log('3 - Error loading session ('+error+') - do createSession');
                    dispatch('createSession', sessionData);
                });
            } else {
                    console.log('2 - No session - do createSession');
                    dispatch('createSession', sessionData);
            }

            commit('SET_APP_LOAD', true );
            
        } catch (error) {
            console.error('Error loading session:', error);
            commit('SET_APP_ERROR', { code: 'Init', text: error });
            throw error;
        }

    },

    async loadSession({ commit }, { sessionToken, sessionData }) {
      try {
        console.log('Loading session: '+sessionToken+' wif data: '+JSON.stringify(sessionData)); // !!
        const response = await axios.post(process.env.VUE_APP_API_URL+'sess/load', {
          session: sessionToken,
          data: sessionData,
        });
        if (response.data.status === 'success') {
            console.log('Session loaded: '+sessionToken);
            commit('SET_SESSION_TOKEN', sessionToken);
            return true;
        } else {
          throw new Error(response.data.message || 'Failed to load session');
        }
      } catch (error) {
        console.error('Error loading session:', error);
        throw error;
      }
    },

    async createSession({ commit }, sessionData) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL+'sess/create', {
          data: sessionData
        });
        if (response.data.status === 'success') {
            console.log('Session created: '+response.data.sessionToken);
            commit('SET_SESSION_TOKEN', response.data.sessionToken);
            return true;
        } else {
          throw new Error(response.data.message || 'Failed to create session');
        }
      } catch (error) {
        console.error('Error creating session:', error);
        throw error;
      }
    },


    // async saveWallet({ commit, state }, wallet) {
    //   try {
    //     // const botCode = localStorage.getItem('botCode');
    //     // const invitationCode = localStorage.getItem('invitationCode');
    //     const response = await api.post('auth/connect', {
    //         data: {
    //             walletAddress: wallet.address,
    //             walletData: wallet,
    //             session: state.sessionToken,
    //             codeBot: state.codeBot,
    //             codeInvite: state.codeInvite
    //         },
    //         session: this.$store.state.sessionToken
    //     });
    //     alert('saveWallet ' + JSON.stringify(response.data));
    //     if (response.data.status === 'success') {
    //         return response.data;
    //     } else {
    //       throw new Error(response.data.message || 'Authentication failed');
    //     }
    //   } catch (error) {
    //     console.error('Failed to authenticate wallet:', error);
    //     throw error;
    //   }
    // },

    async refreshToken({ commit, dispatch, state }) {
      try {
        // Not using api.post here as it might create a loop with the interceptor
        console.log('Store refreshToken '+state.refreshToken);
        const response = await axios.post(process.env.VUE_APP_API_URL+'auth/refresh', {
            refresh_token: state.refreshToken
        });
        console.log('Store refreshToken response '+JSON.stringify(response.data));
        if (response.data.status === 'success') {
            // alert('setting jwt = '+response.data.jwt);
            commit('SET_JWT', response.data.jwt);
            // alert('userdata = '+JSON.stringify(response.data.user_data));
            commit('SET_USER', response.data.user_data);
            // alert('setting refresh token = '+response.data.refresh_token);
            commit('SET_REFRESH_TOKEN', response.data.refresh_token );
            // return response.data.jwt;
            return { jwt: response.data.jwt, status: 'success' };
        }
      } catch (error) {
        console.error('Failed to refresh token:', error);
        dispatch('showNotification', {
            message: 'Unable to refresh authorization token. Please reconnect the wallet.',
            type: 'error'
        });
        // disconnect wallet ?
        commit('CLEAR_AUTH');
        throw error;
      }
    },

    async getUserData({ commit }, jwt) { // used in loadSession - only if there is JWT
        try {
            const response = await api.post('user/data');
            if(response.data && response.data.user_data) {
                commit('SET_USER', response.data.user_data);
            }
            //   const response = await axios.post(process.env.VUE_APP_API_URL+'user/data',
            //         { auth: jwt }
            //   );
            //   if(response.data && response.data.user_data) {
            //         commit('SET_USER', response.data.user_data);
            //   }
            return response.data;
        } catch (error) {
            console.error('Failed to get user data:', error);
            throw error;
        }
    },

    async getUserInfo({ commit }, wallet) { // used in loadSession - if no jwt
        // same as getUserData but using wallet address if there is no jwt
        try {
            console.log('getUserInfo for wall='+ wallet);
            const response = await api.post('wall/info',{
                wall: wallet
            });
            if(response.data && response.data.user_data) {
                commit('SET_USER', response.data.user_data);
            }
            return response.data;
        } catch (error) {
            console.error('Failed to get user data:', error);
            // throw error;
        }
    },

    async generateTonProof({ commit }) {
        try {
          const response = await axios.post(process.env.VUE_APP_API_URL+'auth/generate_proof');
          // alert('generateTonProof '+JSON.stringify(response.data));
          if(response.data && response.data.payload) {
            commit('SET_TON_PROOF', response.data.payload);
          }
          return response.data;
        } catch (error) {
          console.error('Failed to generate TON proof:', error);
          throw error;
        }
    },

    async buyCountry({ dispatch, state }, countryId) {
        try {
            // Implement the logic to buy a country using TonConnect
            const buyReq = { 
                data: { item: countryId },
                wall: state.walletAddr
            };
            // alert('BUY REQUEST = '+JSON.stringify(buyReq));
            const response = await api.post('buy',buyReq);
            if (response.data.status === 'success') {

                // 0.1 TON => 100000000 nanoton
                // X30% up = 0.13 TON => 130000000 nanoton
                // +5% comis = 0.1365 TON => 136500000 nanoton

                const txAddress=response.data.address;
                const txPrice=response.data.price; //186322500; //136500000; 
                const txData=response.data.data;

                dispatch ('sendTX', {txAddress,txPrice,txData});

                // start loader to update information about the county while tx goin
                // check forcountry price change
                
                // this.fetchCountryInfo();
            } else {
                console.error('Failed to buy country:', response.data.message);
                // Handle error (e.g., show notification)
            }
        } catch (error) {
          console.error('Error while buying country:', error);
          // Handle error (e.g., show notification)
        }
      },

    async sendTX({ commit, dispatch }, {txAddress,txPrice,txData}) {

        commit('SET_TX_STATUS', '');
        commit('SET_TX_ERROR', '');

        // const validUntilSec = Math.floor(Date.now() / 1000) + 3600; // 3600 is the number of seconds in 1 hour // convert the timestamp from milliseconds to seconds

        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 3600,
            messages: [
                {
                    address: txAddress, // destination address
                    amount: txPrice, // Toncoin in nanotons
                    payload: txData,
                }
            ]
        }

        // alert('SENDING TX = '+JSON.stringify(transaction));

        try {

            dispatch('showNotification', { message: 'Sending transaction...', type: 'info' });
            
            const result = await tonConnectUI.sendTransaction(transaction);

            // commit('SET_TX_STATUS', 'Transaction sent successfully! Please wait a few minutes for processing.');
            commit('SET_TX_DONE', true);

            // commit('SHOW_TX_SUCCESS_MODAL', true);
            
            // this.transactionStatus = 'Sending transaction...';
            // alert('start await');
            
            // alert('end await, result is -- BOC -- '+JSON.stringify(result));
            // this.txStatus = 'Transaction sent successfully! Please give it a couple of minutes for the transaction to be processed and your purchase to be added to your account.';

            // alert('Transaction result: '+result);

        } catch (error) {

            // alert('TX SEND ERROR '+error);

            // alert('SendTX Error '+error.message);

            // [TON_CONNECT_SDK_ERROR] UserRejectsError: User rejects the action in the wallet.
            // Reject Request
            // Error: Reject Request

            // const errorContainsWords = errorWords.some(word => error.message.includes(word));
            
            console.error('Transaction failed:', error);
            commit('SET_TX_ERROR', error.message);
            commit('SET_TX_DONE', false);
        
            // Show an error modal or notification
            commit('SHOW_TX_ERROR_MODAL', true);
        
            // if (error.message.includes('TON_CONNECT_SDK_ERROR')) {
            //     if (error.message.includes('UserRejectsError')) { // User rejects the action in the wallet
            //         this.txError='Transaction was rejected in the wallet. Please try again later.';
            //     }
            //     else if (error.message.includes('Zr Connect')) { 
            //         this.txError='Please connect your TON wallet in Profile. Telegram may offer to create a new TON Space for the DON TON.';
            //     }
            //     else if (error.message.includes('TonConnectUIError')) { // Transaction was not sent
            //         this.txError='Transaction was not sent. Please try again later.';
            //     }
            //     else {
            //         this.txError = 'Error sending transaction: ' + error.message;
            //     }
            // }

            // if (errorContainsWords) {
            //     this.transactionStatus = 'Please connect your TON wallet by pressing the button below on the right. In case you are not using one yet Telegram will offer to create new TON Space.';
            // } else {
            //     this.transactionError = 'Error sending transaction: ' + error.message;
            // }
            
            // [TON_CONNECT_SDK_ERROR] TonConnectUIError Transaction was not sent
            // alert('Transaction error: '+error);

        }

    },

    async disconnectWallet({ commit, dispatch }) {
        try {

            await tonConnectUI.disconnect();

            commit('SET_WALLET', null);
            console.log('Clear auth');
            commit('CLEAR_AUTH');

            dispatch('showNotification', { 
                message: 'Wallet disconnected successfully', 
                type: 'success' 
            });

        } catch (error) {

            console.error('Wallet disconnection error:', error);
                this.$store.dispatch('showNotification', { 
                message: 'Failed to disconnect wallet: ' + error.message, 
                type: 'error' 
            });

        }
    },

    logout({ commit }) {
        console.log('Logout - Clear auth!');
        commit('CLEAR_AUTH');
    },

//     // Countries

//     async fetchCountries({ commit }, { search, sort, order }) {
//         try {
//           const response = await api.get('item/list', { data: { search, sort, order } });
//           commit('SET_COUNTRIES', response.data.data);
//         } catch (error) {
//           console.error('Failed to fetch countries:', error);
//           // Handle error (e.g., show notification)
//         }
//       },
//       async fetchCountryInfo({ commit }, countryId) {
//         try {
//           const response = await api.get(`item/info`, { data: { id: countryId } });
//           commit('SET_COUNTRY', response.data.data);
//         } catch (error) {
//           console.error('Failed to fetch country info:', error);
//           // Handle error (e.g., show notification)
//         }
//       },
//       async buyCountry({ dispatch }, country) {
//         try {
//           // Implement the logic to buy a country using TonConnect
//           // This will depend on your specific implementation of the smart contract interaction
//           console.log('Buying country:', country);
//           // After successful purchase, refresh the country list and info
//           await dispatch('fetchCountries');
//           if (country.country_id) {
//             await dispatch('fetchCountryInfo', country.country_id);
//           }
//         } catch (error) {
//           console.error('Failed to buy country:', error);
//           // Handle error (e.g., show notification)
//         }
//       },

  },
});

// Axios interceptor
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       try {
//         const newToken = await store.dispatch('refreshToken');
//         originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
//         return api(originalRequest);
//       } catch (refreshError) {
//         return Promise.reject(refreshError);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// Use this api instance for all axios requests
// store.api = api;

export default store;