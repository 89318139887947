export function globalErrorHandler(error, vm, info) {
    // Log the error
    console.error('Uncaught error:', error);
    console.error('Vue instance:', vm);
    console.error('Error info:', info);
  
    // You can send error to a logging service here
    // Display a user-friendly error message
    showErrorNotification('An unexpected error occurred. Please try again later.');
  }
  
  function showErrorNotification(message) {
    // Implement this function to show a notification to the user
    // This could be a custom notification component, alert, or toast
    // For example:
    alert('Global error: '+message);
  }