<template>

    <div v-show="openUser" class="z-[98] bg-black bg-opacity-50 top-0 bottom-0 left-0 right-0 fixed"></div>
    <div v-show="openUser" class="z-[99] bg-slate-800 border border-slate-600 shadow-slate-950 shadow-2xl fixed p-3 top-3 right-3 left-3 rounded-md bottom-3 overflow-x-auto">
        <div class="flex flex-row gap-3 mb-2">
            <h2>Profile</h2>
            <!-- <button class="ml-auto but-5 but-b mt-1" @click="doUserProfile">X</button> -->
        </div>
        <div class="flex flex-col gap-3">
            <!-- <div class="">
                <h5>User</h5>
                <h1>{{ Data.user_data.user_name }}</h1>
            </div> -->
            <div class="border border-slate-700 rounded-md p-3">
                <div class=" flex flex-col gap-2">
                    <h5>TON Wallet</h5>
                    <div class="text-sm mb-0">Connect TON crypto wallet to purchases Countries.
                        
                    </div>
                    <!-- <div id="connect-button" class="ml-auto"></div> -->
                    TONWallet.Address = {{ TONWallet.Address }}
                    <div v-if="TONWallet.Address">
                        <div class="flex flex-row gap-2 bg-orange-600 bg-opacity-25 rounded-md my-1 p-2 w-fit px-3">
                            <img src="@/assets/icon/ton.svg" class="w-5">
                            <div class="">{{ TONWallet.Address }}</div>
                        </div>
                        <div class="w-fit text-zinc-400 text-sm">
                            <button class="read-more" @click="delWallet()">Disconnect</button>
                        </div>
                    </div>
                    <div v-else>
                        <button class="but-3 but-c gap-2 w-fit mb-2"><img src="@/assets/icon/ton.svg" class="w-5" @click="connectWallet()">Connect Wallet</button>
                    </div> 
                    <!-- Info -->
                    <div v-if="!TONWallet.Address">
                        <div class="text-zinc-400 text-sm flex flex-row gap-3">
                            <div class="w-4 mt-1 shrink-0"><img src="@/assets/icon/info.svg" class="w-4"></div>
                            <div class="text-sm">
                                <div>The most convenient way to use TON Spaces in the Wallet Telegram app. Click the "Connect Wallet" button and choose "Telegram Wallet" app.</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div>
                            <router-link @click="closeUserProfile()" :to="{ name: 'shop' }" class="but-4 but-a">Open Shop</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>