<template>

    <div @click="hideNotify" v-if="notification" 
            class="max-w-[1000px] md:w-[80%] p-2 md:p-5 mx-auto fixed 
            max-md:bottom-24 md:bottom-5 left-3 opacity-90 right-3 text-slate-600 text-center bg-white z-[100] shadow-2xl rounded-[12px] md:rounded-[24px] border-zinc-200 border"
            :class="{
            'text-green-600 border-green-600': notification.type === 'success',
            'text-red-600 border-red-600': notification.type === 'error',
            'text-yellow-600 border-yellow-600': notification.type === 'warning',
            'text-blue-600 border-blue-600': notification.type === 'info'
            }">
        {{ notification.message }}
    </div>

    <div v-if="$store.state.showTxSuccessModal" class="fixed z-[100] inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
        <div class="bg-white p-6 rounded-lg border shadow-lg max-w-sm">
            <h2 class="font-bold text-lg text-green-800 mb-4">Transaction Successful</h2>
            <div class="mb-3">Transaction sent successfully! Please wait a few minutes for processing.
            </div><div class="mb-3">Once finished, your Country ownership will be registered. 
                Await a notification in the bot or check Actions in your Profile.</div>
            <div class="flex flex-grow gap-3">
                <a v-if="startBotShow && startBotCode" :href="`https://t.me/tonworbot?start=_B${startBotCode}`" target="_blank" class="b4 bc">Start Bot</a>
                <router-link :to="{ name: 'my' }" class="bc b4">My Countries</router-link>
                <button @click="closeTxSuccessModal()" class="bc b3">Close</button>
            </div>
            <div class="text-zinc-400 text-sm mt-5">It is totally safe to close this window.</div>
        </div>
    </div>

    <div v-if="$store.state.showTxErrorModal" class="fixed z-[100] inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
        <div class="bg-white p-6 rounded-lg border shadow-lg max-w-sm">
            <h2 class="font-bold text-lg text-orange-800 mb-4">Transaction Aborted</h2>
            <div class="mb-3">There was an error during the transaction.</div>
            <div class="text-zinc-200 text-xs mb-2">{{ $store.state.txError }}</div>
            <div class="flex flex-grow gap-3">
                <button @click="closeTxErrorModal()" class="bb b3">Close</button>
            </div>
            <div class="text-zinc-400 text-sm mt-5">It is totally safe to close this window.</div>
        </div>
    </div>

    <div v-if="startBotShow" class="fixed z-[100] inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
        <div class="bg-white p-6 rounded-lg border shadow-lg max-w-sm">
            <h2 class="font-bold text-lg mb-4">Connect to Bot</h2>
            <div class="flex flex-row gap-4">
                <img src="@/assets/boticon.png" class="w-24 h-24 mb-4">
                <div class="mb-3">Start TON World Telegram bot not to miss notifications when a new Country is dropeed or your Country was sold.</div>
            </div>
            <div class="flex flex-grow gap-3">
                <a :href="`https://t.me/tonworbot?start=_B${startBotCode}`" target="_blank" class="b4 bc">Start Bot</a>
                <button @click="closeBotShow()" class="ml-auto bb b5">Skip</button>
            </div>
        </div>
    </div>

    <div v-if="!appLoad" class="p-10 w-full h-screen">
        
        <div class="mx-auto my-auto text-center pt-20">
            <img src="@/assets/logo.png" class="w-24 md:w-28 mb-10 mx-auto">
            <div class="spinner mx-auto mb-4"></div>
            Loading App...
        </div>

    </div>
    <div v-else-if="isError">
        Error!
    </div>
    <div v-else>

        <div class="md:mt-8 flex flex-col md:flex-row max-w-[1000px] md:w-[90%] mx-auto">

            <div class="w-full md:w-[200px] max-md:h-16 z-30">

                <div class="flex md:flex-col flex-row mx-4 md:top-5 md:sticky">

                    <div class="my-5 md:pb-4 flex flex-col">
                        <router-link :to="{ name: 'home' }" class="mb-4"><img src="@/assets/logo.png" class="w-24 md:w-28"></router-link>
                    </div>
                
                    <div class="mt-5 md:hidden max-md:ml-auto">
                        <div class="text-xs text-zinc-800 uppercase">Paid to players</div>
                        <div class="text-base">
                            <img src="@/assets/set/ton.svg" class="w-4 h-4 opacity-80 inline-block mr-1 mb-1">
                            <b>{{ statsVol }}</b> TON
                        </div>
                    </div>

                    <div class="mt-5 md:hidden max-md:ml-auto max-ms:hidden">
                        <div class="text-xs text-zinc-800 uppercase">Net Worth</div>
                        <div class="text-base">
                            <img src="@/assets/set/ton.svg" class="w-4 h-4 opacity-80 inline-block mr-1 mb-1">
                            <b>{{ statsNet }}</b> TON
                        </div>
                    </div>
                    
                </div>
                <div class="z-[50] md:pt-3 md:h-full 
                    max-md:fixed min-w-[180px] max-md:bottom-0 max-md:left-0 max-md:right-0">

                    <MainMenu class="md:top-28 md:sticky" :DEBUG="DEBUG" />

                    <div class="flex flex-col gap-3 mt-5 ml-5 md:top-[420px] md:sticky max-md:hidden ">

                        <div class="">
                            <div class="text-xs text-zinc-400 uppercase">Net Worth</div>
                            <div class="text-base">
                                <img src="@/assets/set/ton.svg" class="w-4 h-4 opacity-80 inline-block mr-1 mb-1">
                                <b>{{ statsNet }}</b> TON
                            </div>
                            <!-- <div class="text-xs text-zinc-400 uppercase">Paid to players</div> -->
                        </div>
                        <div class="">
                            <div class="text-xs text-zinc-400 uppercase">Paid to players</div>
                            <div class="text-base">
                                <img src="@/assets/set/ton.svg" class="w-4 h-4 opacity-80 inline-block mr-1 mb-1">
                                <b>{{ statsVol }}</b> TON
                            </div>
                        </div>

                        </div>

                </div>

            </div>
            <div class="md:w-auto flex-grow max-md:pb-24 max-md:pt-3 pb-5 max-w-[800px] ">

                <header class="flex flex-row justify-normal bg-zinc-200 max-md:mx-3 md:ml-10 px-4 md:px-8 py-3 md:py-5 
                rounded-[18px] md:rounded-[32px] backdrop-filter backdrop-blur bg-opacity-40 
                sticky top-3 border border-zinc-200">
                    <div class="my-auto">
                        <div v-if="walletConnected" class="flex flex-col ms:flex-row gap-2">
                            <router-link :to="{ name: 'user' }" class="flex flex-row gap-2">
                                <img src="@/assets/set/wallet.svg" class="h-5 w-5 inline-block"> 
                                <div v-if="user && user.wallet" class="my-auto font-mono text-sm">{{ formatWallet(user.wallet,4) }}</div>
                                <div v-else-if="walletRaw" class="my-auto font-mono text-sm">{{ formatWallet(walletRaw,4) }}</div>
                                <img src="@/assets/set/warn.svg" class="h-5 w-5 inline-block" v-if="!isAuth"> 
                            </router-link>
                            <div v-if="user" class="my-auto ml-2">
                                <router-link :to="{ name: 'my' }">
                                    <span class="border border-zinc-400 rounded-md px-2 mr-1 text-sm py-1">{{ user.countries_owned }}</span>
                                    countries
                                </router-link>
                            </div>
                        </div>
                        <div v-else class="text-zinc-500 max-ms:hidden">
                            Connect TON wallet to claim Countries
                        </div>
                        
                        <!-- <div class="mt-2" v-if="user">{{  user.countries_owned }} countries</div> -->
                    </div>
                    <div class="my-auto ml-auto max-ms:mx-auto shrink-0">

                        <div v-if="walletConnected">
                            <!-- <button @click="doDisconnect" class="b5 bo shrink-0">Log Out</button> -->
                            <a v-if="user && !user.bot_started" :href="`https://t.me/tonworbot?start=_B${startBotCode}`" target="_blank" class="b5 bc mr-2">Start Bot</a>
                            <router-link :to="{ name: 'history' }" class="b5 bf shrink-0" :class="{ 'md:hidden max-md:hidden' : user && !user.bot_started }">Actions</router-link>
                        </div>
                        <div v-else>
                            <button @click="doConnect" v-if="!wallet" class="b3 bc shrink-0 ">
                                <div class="flex flex-row gap-2">
                                    <span v-if="walletConnecting" class="spinner"></span>
                                    <span v-else><img src="@/assets/social/t.svg" class="w-5 h-5 mt-1"></span>
                                    Connect Wallet
                                </div>
                            </button>
                        </div>

                        <!-- <div v-if="!isLoggedIn">
                            <button @click="doConnect" class="b3 bc shrink-0">Connect Wallet</button>
                            <button @click="handleSignIn" :disabled="isWalletConnecting">
                            {{ isWalletConnecting ? 'Connecting...' : 'Sign In with TON Wallet' }}
                            </button>
                            <p v-if="walletConnectionError" class="error">{{ walletConnectionError }}</p>
                        </div>
                        <div v-else>
                            <h2>Welcome, {{ user.email || 'User' }}</h2>
                            <button @click="doProfile" class="but-a but-3 w-fit mr-3">Profile</button>
                            <button @click="logout">Logout</button>
                        </div> 
                        -->
                        <!-- <button class="b3 bc shrink-0 " @click="connect">Connect Wallet</button> -->
                    </div>
                </header>

                <main class="md:border-l border-zinc-200 px-4 md:pr-0 md:pl-10 mt-5 md:mt-8 overflow-x-hidden">
                    <router-view></router-view>
                </main>

                <div class="border-t border-zinc-200 pt-5 mt-10 md:ml-10 max-md:mx-5"
                >
   
                    <div class="flex flex-col md:flex-row gap-3">

                        <router-link :to="{ name: 'about' }" class="">
                            <img src="@/assets/set/book.svg" class="w-5 h-5 mb-1 mr-2 inline-block">How it works
                        </router-link>
                        
                        <a href="https://t.me/tonworann" target="_blank" class="md:ml-auto">
                            <img src="@/assets/social/t-b.svg" class="w-5 h-5 mb-1 mr-2 inline-block opacity-70">Announcements
                        </a>
                        <a href="https://t.me/tonworchat" target="_blank" class="">
                            <img src="@/assets/social/t-b.svg" class="w-5 h-5 mb-1 mr-2 inline-block opacity-70">Chat Group
                        </a>

                    </div>


                    <!--  :class="{ 'max-md:hidden': !isHome }" -->
                    <div class="mt-5 text-zinc-400 text-sm flex flex-row gap-4 max-md:mt-5"  :class="{ 'max-md:hidden': !isHome }">
                        <router-link :to="{ name: 'terms'}" class="text-zinc-400">Terms</router-link>
                        <router-link :to="{ name: 'feedback'}" class="text-zinc-400" >Feedback</router-link>
                    </div>

                </div>

            </div>
        </div>

    </div>

</template>
  
<script>

 import { mapState, mapActions } from 'vuex';
 // import axios from 'axios';

 import MainMenu from '@/components/MainMenu.vue';
 import UserProfile from '@/components/UserProfile.vue';

 import tonConnectMixin from './mixins/tonConnectMixin';

 export default {
    name: 'TON World',
    mixins: [tonConnectMixin],
    components: {
        MainMenu,
        UserProfile
    },
    watch: {
        '$route'() {
            this.checkRoute();
        },
        // openProfile(newValue) {
        //     if (newValue) this.scrollStop(); else this.scrollStart();
        // },
    },
    data() {
        return {

            DEBUG: false,

            // Data: null,
            // User: null,

            // openMenu: false,
            // openProfile: false,
            // isMiniApp: false,

            isAuth: false,
            walletRaw: '-',

            statsNet: '-',
            statsVol: '-',

            isLoading: false,
            isError: false,
            // isErrorText: null,
            // isErrorCode: null,

            isHome: false, // watch route

        }
    },
    computed: {
        ...mapState(['appData', 'appLoad', 'appError', 'appErrorCode', 'appErrorText']),
        ...mapState(['user']),
        ...mapState(['jwt']),
        ...mapState(['startBotCode', 'startBotShow']),
        ...mapState(['notification']),
        ...mapState(['walletAddr', 'walletData', 'walletConnected', 'walletConnecting']),
        // ...mapState(['codeBot', 'codeInvite']),
        isAuth() { return !!this.jwt; },
    },
    watch: {
        walletAddr(newVal, oldVal) {
            console.log('Wallet address changed:', newVal);
            this.walletRaw = newVal;
        }
    },
    async created() {

        // GET parameters
        const getParams = this.getParams();
        await this.setGetParams(getParams);

        const startBotCode = localStorage.getItem('startBotCode');
        if (startBotCode) {
            this.$store.commit('SET_START_BOT_CODE', startBotCode);
        } else {
            console.log('No startBotCode found in local storage.');
        }

        await this.initSession();

        this.statsNet = this.appData.stats.net;
        this.statsVol = this.appData.stats.vol;

        // // Check/initialize session
        // try {
        //     await this.initSession();
        //     // Session is now initialized or loaded
        // } catch (error) {
        //     console.error('Failed to initialize/load session:', error)
        //     // Error handling is now done in the store action
        // }
        
    },
    mounted() {

        if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;

    },
    methods: {

        ...mapActions(['showNotification', 'doConnect', 'doDisconnect']),
        ...mapActions(['initSession', 'setGetParams']),
        ...mapActions(['connectWallet', 'disconnectWallet']),
        ...mapActions(['setCodeBot', 'setCodeInvite']),
        ...mapActions(['signWallet', 'disconnectWallet']),
        ...mapActions(['logout']),

        // Utility function to get UTM params
        getParams() {

            const urlParams = new URLSearchParams(window.location.search);
            
            const utmSource = urlParams.get('utm_source') || urlParams.get('from') || '';
            
            const utmData = {};
            for (const [key, value] of urlParams.entries()) {
                if (key.startsWith('utm_')) {
                utmData[key] = value;
                }
            }
            const utmDataJson = JSON.stringify(utmData);

            const botCode = urlParams.get('bot') || null;
            const inviteCode = urlParams.get('i') || null;

            console.log('GET' +JSON.stringify({ utmSource, utmDataJson, botCode, inviteCode }));

            return { utmSource, utmData, botCode, inviteCode };

        },

        // getParams() {
        //     const utmSource = this.$route.query.utm_source || this.$route.query.from || '';
        //     alert(JSON.stringify(this.$route.query);
        //     const urlParams = new URLSearchParams(window.location.search);
        //     alert(JSON.stringify(urlParams));
        //     const utmSource = urlParams.get('utm_source') || urlParams.get('utm_from') || '';
        //     // get through router?
        //     // const inviteCode = this.$route.query.invite;
        //     // const botCode = this.$route.query.code;
        //     const utmData = this.getUtmData(); // JSON.stringify all get params with utm_
        //     const botCode = urlParams.get('bot') || null;
        //     // if (botCode) {
        //     //     this.setCodeBot(botCode);
        //     //     console.log('Bot code set:', botCode);
        //     // }
        //     const inviteCode = urlParams.get('i') || null;
        //     // if (inviteCode) {
        //     //     this.setCodeInvite(inviteCode);
        //     //     console.log('Invite code set:', inviteCode);
        //     // }
        //     return { utmSource, utmData, botCode, inviteCode }
        //     // // Remove the parameters from the URL without reloading the page
        //     // ? this.$router.replace({ query: {} });
        //     // if (codeBot || codeInvite) {
        //     //     const newUrl = window.location.pathname + window.location.hash;
        //     //     window.history.replaceState({}, document.title, newUrl);
        //     // }
        // },
        
        // getUtmData() {
        //     const urlParams = new URLSearchParams(window.location.search);
        //     const utmData = {};
        //     for (const [key, value] of urlParams.entries()) {
        //         if (key.startsWith('utm')) {
        //             utmData[key] = value;
        //         }
        //     }
        //     return JSON.stringify(utmData);
        // },
    
        scrollStop() { document.body.classList.add('no-scroll'); },
        scrollStart() { document.body.classList.remove('no-scroll'); },

        isActive(name) { return this.$route.name === name; },

        checkRoute() { // Watch route changed
            if(this.$route.name=='home') this.isHome = true;
        },

        closeBotShow() {
            this.$store.commit('SET_START_BOT_SHOW', false);
        },

        doMenu () { this.openMenu = !this.openMenu; },
        // doSignUp () { this.openSignUp = !this.openSignUp; },
        // doSignIn () { this.openSignIn = !this.openSignIn; },
        doProfile () { this.openProfile = !this.openProfile; },

        hideNotify() {
            this.$store.dispatch('hideNotification');
            this.notification = false;
        },

        closeTxSuccessModal() {
            this.$store.commit('SHOW_TX_SUCCESS_MODAL', false);
        },
        closeTxErrorModal() {
            this.$store.commit('SHOW_TX_ERROR_MODAL', false);
        },

        doDEBUG() { this.DEBUG = !this.DEBUG; },

    },

    beforeDestroy() {
        this.scrollStop();
        if (this.TONConnection) {
            this.TONConnection.onStatusChange(null);
        }
        // window.removeEventListener('message', this.handleTelegramAuth);
    },
    deactivated() {
        this.scrollStop();
    },

}
</script>

<style scoped>

</style>