<template>

    <div class="flex flex-col bg-white max-md:border-t max-md:border-zinc-300 max-md:pt-3
        max-md:flex-row p-2 md:px-5 md:gap-5 md:text-lg
        max-md:items-center max-md:text-center text-base">
        <router-link :to="{ name: 'list' }"   :class="{ 'c1': isActiveTab('list') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/earth.svg" class="w-7 h-7 mx-auto">Countries</router-link>
        <router-link :to="{ name: 'city' }"   :class="{ 'c1': isActiveTab('city') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/city.svg" class="w-7 h-7 mx-auto">Cities</router-link>
        <router-link :to="{ name: 'drop' }"   :class="{ 'c1': isActiveTab('drop') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/drop.svg" class="w-7 h-7 mx-auto">Drop</router-link>
        <router-link :to="{ name: 'invite' }" :class="{ 'c1': isActiveTab('invite') }" class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/invite.svg" class="w-7 h-7 mx-auto">Invite</router-link>
        <router-link :to="{ name: 'user' }"   :class="{ 'c1': isActiveTab('user') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4"><img src="@/assets/set/user.svg" class="w-7 h-7 mx-auto">Profile</router-link>
        <router-link :to="{ name: 'about' }"   :class="{ 'c1': isActiveTab('about') }"   class="flex flex-col md:flex-row gap-2 md:gap-4 md:w-fit w-1/4 max-md:hidden"><img src="@/assets/set/book.svg" class="w-7 h-7 mx-auto">Help</router-link>
    
    </div>

    <!-- <div class="max-w-[800px] md:w-[80%] md:p-5 mx-auto fixed bottom-0 left-0 right-0 p-5 bg-zinc-100 md:h-16 z-30">

        <div class="flex flex-row px-5 items-center text-center text-base">
            <router-link :to="{ name: 'list' }"   class=" w-1/4"><img src="@/assets/set/globe.svg"  class="w-7 h-7 mb-1 mx-auto">Countries</router-link>
            <router-link :to="{ name: 'drop' }"   class=" w-1/4"><img src="@/assets/set/drop.svg"   class="w-7 h-7 mb-1 mx-auto">Drop</router-link>
            <router-link :to="{ name: 'invite' }" class=" w-1/4"><img src="@/assets/set/invite.svg" class="w-7 h-7 mb-1 mx-auto">Invite</router-link>
            <router-link :to="{ name: 'user' }"   class=" w-1/4"><img src="@/assets/set/user.svg"   class="w-7 h-7 mb-1 mx-auto">Profile</router-link>
           
        </div>

    </div> -->

</template>

<script>
//  import { mapState, mapActions } from 'vuex';
 export default {

    props: { },

    data() {
        return {

            DEBUG: false,
 
        }
    }
}
</script>